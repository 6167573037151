import * as C from "@components";
import { runWithFailedPageLoggingContext } from "@services";
import { PublicConfigurations } from "@types";
import CookieBanner from "components/CookieBanner";
import PageNotFound from "components/ErrorPage";
import Splash, { SplashData } from "components/Splash";
import { ToastNotification } from "components/ToastNotification";
import {
  additionalContentTypeTemplates,
  siteSettingsReferences,
} from "constants/contentTypeTemplates";
import { Environment } from "constants/pages.constants";
import AppContext from "context/AppContext";
import {
  EncryptedGetStaticProps,
  withEncryptionStatic,
} from "context/AppEncryptionContext";
import { EnquiryProvider } from "context/EnquiryContext";
import { ModalLeadFormProvider } from "context/ModalLeadFormContext";
import { ModalPageNotFoundProvider } from "context/ModalPageNotFoundContext";
import I18nContext, {
  getSEOFromComponents,
  I18nContextData,
} from "i18n/context/LanguageContext";
import { Language, LanguageCode } from "i18n/types";
import isEmpty from "lodash/isEmpty";
import type { GetStaticPaths, NextPage } from "next";
import { setCookie } from "nookies";
import { getConfiguration } from "page-services/configuration.service";
import {
  generateRobotsTxt,
  getAnnouncements,
  getCurrentEnvironment,
  getSiteSettings,
  getSplashData,
  getStackData,
} from "page-services/helper.service";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getPageLayoutDataByURL } from "services/allPage.service";
import { Announcement } from "types/Annoucement";
import { ErrorPages } from "types/ErrorPage.interface";
import { RedisExportFailureKeys } from "types/redis";
import { getComponentConfigs } from "utils/componentConfigs";
import gtmUtils from "utils/gtm";
import type { QParams } from "../../typings/global";

interface Props {
  data: any;
  currentLanguage: Language;
  defaultLanguage: Language;
  supportedLanguages: Record<LanguageCode, Language>;
  stackName: string;
  siteSettings: {
    cookies: {
      cta_text: string;
      description: string;
    };
    error_pages: ErrorPages;
    embeded_scripts: {
      google_tag_manager: string;
      google_optimize: string;
    };
    font_family: string;
    font_face: string;
  };
  announcements: Announcement[];
  splash: SplashData;
  publicConfigurations: PublicConfigurations;
  isHeaderPresent: boolean;
}

type ComponentName = keyof typeof C;

const Page: NextPage<Props> = (props: Props) => {
  const {
    data: components,
    siteSettings,
    announcements,
    splash,
    isHeaderPresent,
  } = props;
  const [language, setLanguage] = useState<Language>(props.currentLanguage);

  const changeLanguage = useCallback(
    (locale: string) => {
      const language = props.supportedLanguages[locale];
      setLanguage(language);
    },
    [props.supportedLanguages]
  );

  const componentConfigs = useMemo(() => {
    return getComponentConfigs(components, props.publicConfigurations);
  }, [components, props.publicConfigurations]);

  useEffect(() => {
    setCookie(null, "lang", language.languageCode, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  }, [language.languageCode]);

  const i18nContextData: I18nContextData = useMemo(
    () => ({
      language,
      supportedLanguages: props.supportedLanguages,
      defaultLanguage: props.defaultLanguage,
      changeLanguage: changeLanguage,
      countryStackName: props.stackName,
      countryCode: props.publicConfigurations?.publicCountryCode || "",
      siteSettings: siteSettings,
      seo: getSEOFromComponents(components),
      componentConfigs,
    }),
    [
      changeLanguage,
      language,
      props.defaultLanguage,
      props.stackName,
      props.supportedLanguages,
      siteSettings,
      componentConfigs,
    ]
  );

  useEffect(() => {
    if (props.data?.url?.length === 1) {
      setTimeout(() => {
        gtmUtils.pageView(i18nContextData.componentConfigs.gaPageData);
      }, 0);
    }
  }, []);

  return (
    <I18nContext.Provider value={i18nContextData}>
      <AppContext.Provider
        value={{ publicConfigurations: props.publicConfigurations }}
      >
        <EnquiryProvider value={props}>
          <ModalLeadFormProvider value={props}>
            <ModalPageNotFoundProvider>
              {!isEmpty(announcements) && isHeaderPresent && (
                <ToastNotification announcements={announcements} />
              )}
              <div>
                {components.layout.map((c: any, index: number) => {
                  const name: ComponentName = c.nameComponent as ComponentName;
                  const Component = C[name] as React.FC<any>;
                  if (!Component) return null;

                  if (name === "HeaderSchema") {
                    return (
                      <Component
                        key={`component-${name}-${index}`}
                        data={{
                          ...c.dataComponent,
                        }}
                      />
                    );
                  }

                  return (
                    <Component
                      key={`component-${name}-${index}`}
                      data={{
                        ...c.dataComponent,
                        publicConfigurations: props.publicConfigurations,
                      }}
                    />
                  );
                })}
              </div>
              {!isEmpty(siteSettings?.cookies) && (
                <CookieBanner data={siteSettings?.cookies} />
              )}
              <Splash data={splash} />
            </ModalPageNotFoundProvider>
          </ModalLeadFormProvider>
        </EnquiryProvider>
      </AppContext.Provider>
    </I18nContext.Provider>
  );
};

export const getStaticProps: EncryptedGetStaticProps<Props> =
  withEncryptionStatic(async (ctx) => {
    // TODO: move redis to some global places

    let { params } = ctx;
    const stackData = await getStackData();
    const languageCode = params?.lang as string;
    const isValidLang = Object.values(stackData.languages).find(
      (language) => language.languageCode === languageCode
    );
    if (!isValidLang) {
      return {
        notFound: true,
      };
    }
    const currentLanguage =
      stackData.languages[languageCode] || stackData.defaultLanguage;

    const currentUrl = "";

    const publicConfigurations = {
      publicRootUrl: getConfiguration(
        "NEXT_PUBLIC_ROOT_URL",
        process.env.NEXT_PUBLIC_ROOT_URL
      ),
      publicCountryCode: getConfiguration(
        "NEXT_PUBLIC_COUNTRY_CODE",
        process.env.NEXT_PUBLIC_COUNTRY_CODE
      ),
      apiAuthBase64: getConfiguration(
        "NEXT_PUBLIC_API_AUTH_BASE64",
        process.env.NEXT_PUBLIC_API_AUTH_BASE64
      ),
      dtcCampaignId: getConfiguration(
        "NEXT_PUBLIC_TH_SAVE_LEAD_DTC_CAMPAIGN_ID",
        process.env.NEXT_PUBLIC_TH_SAVE_LEAD_DTC_CAMPAIGN_ID
      ),
      publicApiUrl: getConfiguration(
        "NEXT_PUBLIC_API_URL",
        process.env.NEXT_PUBLIC_API_URL
      ),
      fCounterUrl: getConfiguration(
        "NEXT_PUBLIC_FCOUNTER_URL",
        process.env.NEXT_PUBLIC_FCOUNTER_URL
      ),
      fCounterToken: getConfiguration(
        "NEXT_PUBLIC_FCOUNTER_TOKEN",
        process.env.NEXT_PUBLIC_FCOUNTER_TOKEN
      ),
      flexibleFormApiUrl: getConfiguration(
        "NEXT_PUBLIC_FLEXIBLE_FORM_API_URL",
        process.env.NEXT_PUBLIC_FLEXIBLE_FORM_API_URL
      ),
      flexibleFormApiKey: getConfiguration(
        "NEXT_PUBLIC_FLEXIBLE_FORM_API_KEY",
        process.env.NEXT_PUBLIC_FLEXIBLE_FORM_API_KEY
      ),
      csBranchName: getConfiguration(
        "CONTENTSTACK_BRANCH",
        process.env.CONTENTSTACK_BRANCH || "main"
      ),
      publicExportSite: getConfiguration(
        "NEXT_PUBLIC_EXPORT_SITE",
        process.env.NEXT_PUBLIC_EXPORT_SITE
      ),
    };

    return await runWithFailedPageLoggingContext(
      RedisExportFailureKeys.Index,
      languageCode,
      currentUrl,
      async () => {
        const [data, siteSettings, announcements, splash] = await Promise.all([
          // translationService.fetchAllTranslation(currentLanguage.languageCode as any),
          getPageLayoutDataByURL(
            currentUrl,
            currentLanguage.locale,
            Object.keys(stackData.languages).length
          ),

          getSiteSettings(
            currentLanguage.locale,
            stackData,
            siteSettingsReferences()
          ),

          additionalContentTypeTemplates().includes("oneweb_announcement") &&
            getAnnouncements(currentLanguage.locale),

          additionalContentTypeTemplates().includes("splash") &&
            getSplashData(currentLanguage.locale),
        ]);
        const currentEnv = getCurrentEnvironment();
        if (currentEnv === Environment.UAT || currentEnv === Environment.DEV) {
          const robotsBody = `User-agent: *\nDisallow: /`;
          generateRobotsTxt(robotsBody);
        } else if (siteSettings?.robots?.body) {
          generateRobotsTxt(siteSettings?.robots?.body);
        }

        return {
          props: {
            data,
            isHeaderPresent: data.layout.some(
              (layout: any) =>
                layout?.nameComponent === "CSHeader" &&
                !isEmpty(layout?.dataComponent)
            ),
            currentLanguage: currentLanguage,
            defaultLanguage: stackData.defaultLanguage,
            supportedLanguages: stackData.languages,
            stackName: stackData.stackName,
            siteSettings,
            announcements,
            splash,
            publicConfigurations,
          },
          revalidate: 1,
        };
      }
    );
  });

export const getStaticPaths: GetStaticPaths<QParams> = async () => {
  const stackData = await getStackData();

  //const failedUrlsString = await redis.get("failedUrls");
  const failedUrlsString = "[]";
  const failedUrls = JSON.parse(failedUrlsString);

  //TODO: move to function to reuse

  if (failedUrls && failedUrls.length) {
    let paths: any = [];
    for (const locale in stackData.languages) {
      for (const pagePath of failedUrls) {
        if (pagePath) {
          let arrSlugs = pagePath
            ?.split("/")
            .filter((str: string) => str !== "");

          if (arrSlugs?.length) {
            paths.push({ params: { lang: locale, slug: arrSlugs } });
          }
        }
      }
    }

    return {
      paths,
      fallback: false,
    };
  }

  const paths = Object.entries(stackData.languages).map(
    ([languageCode, _]) => ({
      params: { lang: languageCode },
    })
  );

  return {
    paths,
    fallback: "blocking",
  };
};

export default Page;
